import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Header.module.scss';

export const Header: React.FC = () => {
  const router = useLocation();
  const toCurrentRouteClass = (expectedRoute: string) =>
    router.pathname === expectedRoute ? styles.currentRoute : '';
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.pathname]);

  const [hover, setHover] = useState<boolean>(false);

  return (
    <div className={styles.header}>
      {/* <img src="/img/icon.png" className={styles.logo} alt="Fame" /> */}
      <img src="/img/logo.png" className={styles.logo} alt="Fame" />
      {/* <h3>Lovespace</h3> */}
      <div className={styles.hLine} />
      <Link className={`${toCurrentRouteClass('/')}`} to="/">
        Home
      </Link>
      <Link className={`${toCurrentRouteClass('/about')}`} to="/about">
        About
      </Link>
      <a href="http://Twitter.com/lovespaceapp" target="_blank">
        <img
          src={`/img/twitter${hover ? 'Color' : ''}.svg`}
          alt="twitter"
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        />
      </a>
    </div>
  );
};

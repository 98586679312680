import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Header } from './components/header';
import { Home } from './components/home';
import { About } from './components/about';
import { Privacy } from './components/privacy';
import { Terms } from './components/terms';
import { Footer } from './components/footer';
import { UnSubscribe } from './components/unSubscribe';

function App() {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route path="/about" exact component={About} />
          <Route path="/terms" exact component={Terms} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/unsubscribe" exact component={UnSubscribe} />
          <Route path="/:referrer" exact component={Home} />
          <Route path="/" component={Home} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;

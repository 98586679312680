import styles from '../privacy//Privacy.module.scss';

export const Terms: React.FC = () => {
  return (
    <div className={styles.textPage}>
      <div className={styles.textContent}>
        <h1>FAME TERMS OF USE</h1>
        <h3>Effective Date: May 3 2022</h3>
        <p>
          Hello, and welcome to Fame! These Terms of Use (“Terms”) describe your
          rights and obligations when using the Services (defined below) offered
          by us or our affiliates.
          <br />
          <br />
          Please note that these Terms of use contain{' '}
          <b>
            Indemnification, Governing Law, Dispute Resolution, Assumption of
            Risk and Disclaimer of Warranties and Limitation of Liability
          </b>{' '}
          Sections that affect your rights, including your ability to bring
          legal claims against us. Please read these Terms, our Privacy Policy
          and any other terms referenced in this document carefully. If you do
          not agree to be bound by these Terms, you are not permitted to use our
          Services.
        </p>
        <h3>1. Introduction</h3>
        <p>
          Fame is the world’s first NFT dating application, and is operated by
          Space Runners, d/b/a Fame (“Fame”, “Space Runners”, “we”, “us”,
          “our”). These Terms govern your access to and use of the Fame
          Application (“Application”) and any other related and connected
          platforms or services (collectively, the “Services”). By accessing the
          Services, you agree that you have read, understand, and accept all of
          the terms and conditions contained in these Terms, including Section
          14 ("Dispute Resolution"), as well as the Privacy Policy.
        </p>
        <h3>2. Using Our Services</h3>
        <p>
          <b>Important disclaimers.</b> Fame is not intended as, and does not
          provide, any investment or financial advice whatsoever. As with any
          financial or investment decisions, you should conduct your own
          research and due diligence investigation, to properly evaluate the
          benefits and risks of any investment or financial transaction. You
          should also seek the advice and guidance of qualified accountants,
          financial advisors, tax advisors, legal counsels and investment
          advisors, in connection with any investment or financial transaction.
        </p>
        <br />
        <br />

        <p>
          <b>Eligibility.</b> To be eligible to use the Services you must be of
          the legal age in the jurisdiction you reside in at the time of using
          the Services. If you are below the legal age in your jurisdiction, you
          are prohibited from using the Services.{' '}
        </p>
        <br />
        <br />
        <p>
          By creating an account and using the Service, you represent and
          warrant that:
        </p>
        <ul>
          <li>
            <p>you can form a binding contract with Fame,</p>
          </li>
          <li>
            <p>
              you are not a person who is barred from using the Service under
              the laws of the United States or any other applicable jurisdiction
              (for example, you do not appear on the U.S. Treasury Department’s
              list of Specially Designated Nationals or face any other similar
              prohibition),
            </p>
          </li>
          <li>
            <p>
              you will comply with this Agreement and all applicable local,
              state, national and international laws, rules and regulations,
              including without limitation, privacy laws, intellectual property
              laws, anti-spam laws, and regulatory requirements,
            </p>
          </li>
          <li>
            <p>
              you have not committed, been convicted of, or pled no contest to a
              felony, a sex crime, or any crime involving violence or a threat
              of violence, unless you have received clemency for a non-violent
              crime and we have determined that you are not likely to pose a
              threat to other users of our Service, and that you are not
              required to register as a sex offender with any state, federal or
              local sex offender registry,
            </p>
          </li>
          <li>
            <p>
              you will use the latest version of the Service (via app or
              website),
            </p>
          </li>
          <li>
            <p>you do not have more than one account on the Service, and</p>
          </li>
          <li>
            <p>
              you have not previously been removed from the Service by us,
              unless you have our express written permission to create a new
              account.
            </p>
          </li>
        </ul>
        <p>
          If at any time you cease to meet these requirements, all authorization
          to access our Service or systems is automatically revoked, and you
          must immediately delete your account.
        </p>
        <p>
          <b>Updates to Terms of Use.</b> Your use of the Services is subject to
          your agreement to these Terms, which may be updated by us at any time
          with or without notice to you. The Effective Date of the most up to
          date Terms will be provided at the top of each version of these Terms,
          respectively. Any such updates will be effective upon our publishing
          such updated Terms. If you do not agree with these Terms, you may not
          use the Services.
        </p>

        <p>
          <b>User Accounts.</b> In order to access our Services, you have to
          create a user account (“User Account”). By creating a User Account,
          you agree: (a) to provide accurate, current and complete information
          about yourself; (b) to maintain and promptly update from time to time
          as necessary your information; (c) to immediately notify us if you
          discover or otherwise suspect any security breaches related to the
          Services; and (d) that you are fully responsible for all activity on
          the Services that occurs under your email and password combination. We
          may, in our sole discretion, refuse to allow you create a User
          Account, or suspend or terminate any User Account.
          <br />
          <br />
          You also agree that you will not:
        </p>
        <ul>
          <li>
            <p>
              Use the Services under a different email address if we’ve disabled
              access for you under a different email address, unless you have
              our written permission first; or
            </p>
          </li>
          <li>
            <p>
              Use or access the Services through any unauthorized third party
              application(s) or client(s), or to disclose or share your User
              Account information such as your email address and password with
              any other person.
            </p>
          </li>
        </ul>
        <p>
          In order to create a User Account, you may be required to provide
          additional information, such as your name, email address, username,
          date of birth, profile picture, wallet address, and other information
          as required by Fame.{' '}
        </p>

        <p>
          <b>Account Communication.</b> By creating a User Account, you consent
          to receive electronic communications from Fame (e.g., via email, push
          notification, or by posting notices to the Services, etc.). These
          communications may include notices about your use of the Services
          (e.g., email changes and/or other transactional information) and are
          part of your relationship with us. You agree that any notices,
          agreements, disclosures or other communications that we send to you
          electronically will satisfy any legal communication requirements,
          including, but not limited to, that such communications be in writing.
          You should maintain copies of electronic communications from us by
          printing a paper copy or saving an electronic copy.{' '}
        </p>
        <p>
          <b>Safety</b>
        </p>
        <p>
          You agree to treat other users in a courteous and respectful manner,
          both on and off our Services and to be respectful when communicating
          with any of our customer care representatives or other employees.
          Though Fame strives to encourage a safe member experience through
          features like the double opt-in that allows members to communicate
          only after they have both indicated interest in one another,{' '}
          <b>
            Fame is not responsible for the conduct of any member on or off of
            the Service. You agree to use caution in all interactions with other
            members, particularly if you decide to communicate off the Service
            or meet in person. You agree that you will not provide your
            financial information (for example, your credit card or bank account
            information), or wire or otherwise send money to other members.
          </b>
        </p>
        <p>
          <b>
            YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS.
            FAME MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OR
            COMPATIBILITY OF MEMBERS.
          </b>
        </p>
        <p>
          <b>COMPATIBILITY OF MEMBERS.</b>
        </p>
        <p>
          FAME RESERVES THE RIGHT TO CONDUCT ANY CRIMINAL BACKGROUND CHECK OR
          OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER SEARCHES) AT ANY TIME
          USING AVAILABLE PUBLIC RECORDS OBTAINED BY IT OR WITH THE ASSISTANCE
          OF A CONSUMER REPORTING AGENCY, AND YOU AGREE THAT ANY INFORMATION YOU
          PROVIDE MAY BE USED FOR THAT PURPOSE.
        </p>
        <h3>3. Digital Wallet</h3>
        <p>
          <b>Digital Wallet.</b> As part of the Services, you are able to
          connect your so-called “digital asset wallet” (“Digital Wallet”) to
          your account. You may be required to provide us additional information
          to connect your Digital Wallet, such as an email address and password.
          We do not offer our own digital asset wallet and any Digital Wallet
          that you connect to your User Account is subject to the Terms and
          Conditions of that service provider and we have do not have any access
          to, or control over, your connected Digital Wallet.
        </p>
        <h3>4. SPACE Points</h3>
        <p>
          <b>SPACE Points.</b> As you use our Services, you can collect points
          (“SPACE Points”). SPACE Points are offered for completing certain
          tasks of activities on our Services. SPACE points are not currency and
          have no value outside of the Services. Fame may manage, regulate,
          control, modify or eliminate SPACE Points at any time. Fame shall have
          no liability to you or any third party in the event that Fame
          exercises any such rights. SPACE Points may only be redeemed through
          the Service. SPACE Points can be redeemed for additional features or
          functionality.
        </p>
        <h3>5. User Conduct</h3>
        <p>
          <b>Prohibited Uses.</b> You may not use the Services to engage in the
          following categories of activity ("Prohibited Uses"). The specific
          types of use listed below are representative, but not exhaustive, and
          Fame reserves the right to update or amend such list in its sole
          discretion at any time, with or without notice to you. These
          Prohibited Uses are in addition to the Community Guidelines (discussed
          below). If you are uncertain as to whether or not your use of the
          Services involves a Prohibited Use or have questions about how these
          requirements apply to you, please contact us at hi@fame.xyz By
          becoming a Service User, you agree that you will not use the Services
          to do or undertake any of the following, as determined by Fame in its
          sole discretion:
        </p>
        <ul>
          <li>
            <p>
              <b>Abusive Activity</b>: Actions which: (i) impose an unreasonable
              or disproportionately large load on our infrastructure, or
              detrimentally interfere with, intercept, or expropriate any
              system, data, or information; (ii) transmit or upload any material
              to the Services that contains viruses, trojan horses, worms or any
              other harmful or deleterious programs; (iii) attempt to gain
              unauthorized access to the Services, other Digital Wallets not
              belonging to you, computer systems or networks connected to the
              Services, through password mining or any other means; (iv) use the
              account of any third party to access or use the Services, except
              in the case of applications which are specifically authorized by a
              third party to access such third party’s Digital Wallet and
              information; or (v) transfer your access or rights to use the
              Services to a third party, unless by operation of law or with the
              express permission of Fame. There is no tolerance for
              objectionable content or abusive users.
            </p>
          </li>
          <li>
            <p>
              <b>Unlawful Activity</b>: Activity which would violate, or assist
              in violation of, any law, statute, ordinance or regulation,
              sanctions programs administered in any of the countries where Fame
              conducts business, including but not limited to the U.S.
              Department of Treasury's Office of Foreign Assets Control
              ("OFAC"), or which would involve proceeds of any unlawful
              activity.{' '}
            </p>
          </li>
          <li>
            <p>
              <b>Abuse Other Users</b>: Interfere with another individual's
              access to or use of the Services; defame, abuse, extort, harass,
              stalk, threaten or otherwise violate or infringe the legal or
              other rights (such as, but not limited to, rights of privacy,
              publicity and intellectual property) of others; or harvest or
              otherwise collect information from the Services about others,
              including without limitation email addresses and/or public or
              private Digital Wallet keys, without proper consent.
            </p>
          </li>
          <li>
            <p>
              <b>Fraud</b>: Activity which operates to defraud Fame, any Fame
              user(s) or any other person, or provide any false, inaccurate or
              misleading information to Fame.
            </p>
          </li>
          <li>
            <p>
              <b>Copyright violations</b>: Activity which intentionally
              infringes upon another’s copyright, or other intellectual rights,
              protections, including unauthorized copying or performing of
              copyrighted works available on the Services.{' '}
            </p>
          </li>
        </ul>
        <h3>6. Access to our Services</h3>
        <p>
          <b>Suspension, Termination, and Cancellation.</b> Fame may, at its
          option and in its sole discretion: (a) suspend or restrict your User
          Account and/or access to any or all of the Services, and/or (b)
          deactivate or cancel your User Account and block your access to the
          Services, if: (i) we are so required by a facially valid subpoena,
          court order, or binding order of any government authority; (ii) we
          reasonably suspect you of using the Services in connection with a
          Prohibited Uses; (iii) your use of the Services is subject to any
          pending litigation, investigation or government proceeding and/or we,
          in our sole discretion, perceive a heightened risk of legal or
          regulatory non-compliance associated with your activity; (iv) any of
          our service partners are unable to support your use thereof; (v) you
          take any action that Fame deems in its sole discretion as
          circumventing Fame’s controls, or abusing promotions which Fame may
          offer from time to time; or (vi) you breach these Terms.{' '}
        </p>
        <p>
          <b>Notice to You.</b> If Fame suspends or closes your account or
          terminates your use of the Services for any reason, we will provide
          you with notice of our actions, unless a court order or other legal
          process prevents or prohibits Fame from providing you with such
          notice. You acknowledge that Fame’s decision to take certain actions,
          including limiting access to, suspending, or disassociating your
          Digital Wallet from the Services, may be based on confidential
          criteria that are essential to Fame’s risk management and/or security
          protocols. You agree that Fame is under no obligation to disclose the
          details of its risk management and/or security procedures to you.
        </p>
        <h3>7. Rights you Grant Us</h3>
        <p>
          By creating a User Account, you grant to Fame a worldwide,
          transferable, sub-licensable, royalty-free, right and license to host,
          store, use, copy, display, reproduce, adapt, edit, publish, modify,
          reformat, incorporate into other works, advertise, distribute, and
          otherwise make available to the general public information you
          authorize us to access from third parties such as Facebook, Google, or
          Apple, as well as any information you post, upload, display or
          otherwise make available (collectively, “post”) on the Service or
          transmit to other members (collectively, “Content”). Fame’s license to
          your Content shall be non-exclusive, except that Fame’s license shall
          be exclusive with respect to derivative works created through use of
          the Service. For example, Fame would have an exclusive license to
          screenshots of the Service that include your Content. In addition, so
          that Fame can prevent the use of your Content outside of the Service,
          you authorize Fame to act on your behalf with respect to infringing
          uses of your Content taken from the Service by other members or third
          parties. This expressly includes the authority, but not the
          obligation, to send notices pursuant to 17 U.S.C. § 512(c)(3) (i.e.,
          DMCA Takedown Notices) on your behalf if your Content is taken and
          used by third parties outside of the Service. Our license to your
          Content is subject to your rights under applicable law (for example
          laws regarding personal data protection to the extent any Content
          contains personal information as defined by those laws) and is for the
          limited purpose of operating, developing, providing, and improving the
          Service and researching and developing new ones. You agree that any
          Content you place or that you authorize us to place on the Service may
          be viewed by other members and may be viewed by any person visiting or
          participating in the Service (such as individuals who may receive
          shared Content from other Fame members).
          <br />
          <br />
        </p>
        <p>
          You understand and agree that we may monitor or review any Content you
          post as part of the Service. We may delete any Content, in whole or in
          part, that in our sole judgment violates this Agreement or may harm
          the reputation of the Service.
          <br />
          <br />
        </p>
        <p>
          When communicating with our customer care representatives, you agree
          to be respectful and kind. If we feel that your behavior towards any
          of our customer care representatives or other employees is at any time
          threatening, harassing, or offensive, we reserve the right to
          immediately terminate your account.
          <br />
          <br />
        </p>
        <p>
          In consideration for Fame allowing you to use the Service, you agree
          that we, our affiliates, and our third-party partners may place
          advertising on the Service. By submitting suggestions or feedback to
          Fame regarding our Service, you agree that Fame may use and share such
          feedback for any purpose without compensating you.
          <br />
          <br />
        </p>
        <p>
          You agree that Fame may access, store, and disclose your account
          information and Content if required to do so by law, by performing its
          agreement with you, or in a good faith belief that such access,
          storage or disclosure satisfies a legitimate interest, including to:
          (i) comply with legal process; (ii) enforce the Agreement; (iii)
          respond to claims that any Content violates the rights of third
          parties; (iv) respond to your requests for customer service; or (v)
          protect the rights, property or personal safety of the Company or any
          other person.
        </p>
        <h3>8. Copyright and License to You</h3>
        <p>
          <b>Information Ownership.</b> Unless otherwise indicated in writing by
          us, the Services and all content and other materials contained
          therein, including, without limitation, the Fame logo and all designs,
          text, graphics, pictures, information, data, software, sound files
          and/or other files related thereto and/or associated therewith and the
          selection and arrangement thereof (collectively, “Content”) are and
          shall remain the sole and proprietary property of Fame and/or our
          affiliates or licensors, including the Developers, if and as
          applicable. If you print, copy, modify, download or otherwise use or
          provide any other person with access to any part of the Services in
          breach of these Terms, your right to use the Services will terminate
          immediately and you must, at our option, either return or destroy any
          Content you have copied, downloaded , or otherwise obtained from the
          Services. No right, title or interest in or to the Services or any
          Content thereon is or shall be transferred to you, and all rights not
          expressly granted herein are reserved by Fame and/or its affiliates.
          Any use of the Services not expressly permitted by these Terms shall
          be deemed a material breach of these Terms and may violate copyright,
          trademark and/or other laws.
        </p>
        <p>
          <b>Third Party Intellectual Property.</b> Notwithstanding anything to
          the contrary in these Terms, the Services and Content may include
          software components provided by Fame or its affiliates or a third
          party that are subject to separate license terms, in which case those
          license terms will govern the usage of such software components, as
          applicable.
        </p>
        <p>
          <b>Limited License to You.</b> These Terms grant users a limited and
          non-exclusive right to use the Services, including all Content
          thereon. Except as indicated otherwise herein or in any additional
          terms or conditions, you may not reproduce, distribute, modify, create
          derivative works of, publicly display, publicly perform, republish,
          download, store, transmit or otherwise exploit any of the Content on
          our Services, except as follows:
        </p>
        <ul>
          <li>
            <p>
              Your device may temporarily store copies of such materials in RAM
              incidental to your accessing and viewing those materials.
            </p>
          </li>
          <li>
            <p>
              You may store files that are automatically cached by your web
              browser for display enhancement purposes.
            </p>
          </li>
          <li>
            <p>
              If we provide any so-called “social media” features as part of the
              Services, you may take such actions as are enabled by such
              features and are consistent with these Terms.
            </p>
          </li>
        </ul>
        <ul>
          You must not:
          <li>
            <p>Modify copies of any Content from the Services.</p>
          </li>
          <li>
            <p>
              Use any illustrations, photographs, video or audio sequences or
              any graphics available through the Services separately from the
              accompanying text.
            </p>
          </li>
          <li>
            <p>
              Delete or alter any copyright, trademark or other proprietary
              rights notices from copies of materials available through the
              Services.
            </p>
          </li>
        </ul>
        <p>
          If you wish to make any use of Content other than that set out in this
          Section, please address your request to: hi@fame.xyz
        </p>
        <p>
          <b>Third Party Services.</b> The Services may contain links to
          third-party websites (“Third-Party Websites”) and/or applications
          (“Third-Party Applications”). When you click on a link to a
          Third-Party Website or Third-Party Application, we will not warn you
          that you have left the Services and are subject to the terms and
          conditions (including privacy policies, if and as applicable) of
          another website or destination. Such Third-Party Websites and
          Third-Party Applications are not under the control of Fame. Fame is
          not responsible for any Third-Party Websites or Third-Party
          Applications and shall not be liable with respect to any use thereof.
          Fame provides these Third-Party Websites and Third-Party Applications
          only as a convenience and does not review, approve, monitor, endorse,
          warrant or make any representations with respect to Third-Party
          Websites or Third-Party Applications or their products or services.
          You use all links in Third-Party Websites, and Third-Party
          Applications at your own risk. When you leave our Services, these
          Terms and any of our other policies no longer govern. You should
          review all applicable agreements and policies, including privacy and
          data gathering practices, of any Third-Party Websites or Third-Party
          Applications, and should make whatever investigation you feel
          necessary or appropriate before proceeding with any transaction with
          any third party.
        </p>
        <p>
          <b>Links to our Services.</b> You are granted a limited, nonexclusive,
          nontransferable right to create a text hyperlink to the Services for
          noncommercial purposes, provided that such link does not portray Fame
          or our affiliates or any of our products or services in a false,
          misleading, derogatory or otherwise defamatory manner, and does not
          imply that Fame is affiliated with or supports or endorses any product
          or service, and provided further that the linking site does not
          contain any adult or illegal material or any material that may be
          deemed, in Fame’s sole discretion, offensive, harassing or otherwise
          objectionable. This limited right may be revoked or modified by us at
          any time, for any reason. You may not use a logo or other proprietary
          graphic or intellectual property of Fame to link to the Services or
          Content without our express written permission. Further, you may not
          use, frame or utilize framing techniques to enclose any Fame
          trademark, logo or other proprietary information, including the images
          found on the Services, the content of any text or the layout or design
          of any page, or form contained on a page, on the Services without our
          express written permission.
        </p>
        <p>
          <b>Trademark.</b> The Fame logo and any Fame product or service names,
          logos or slogans that may appear on the Services are trademarks of
          Fame or our affiliates and may not be copied, imitated or used, in
          whole or in part, without our prior express written permission. You
          may not use any so-called “metatags” or other “hidden text” utilizing
          “Fame” or any other name, trademark or product or service name of Fame
          or our affiliates without our prior written permission. In addition,
          the look and feel of the Services and Content, including, without
          limitation, all page headers, custom graphics, button icons and
          scripts, constitute the service mark, trademark or trade dress of Fame
          and may not be copied, imitated or used, in whole or in part, without
          our prior written permission. All other trademarks, registered
          trademarks, product names and Fame names or logos mentioned on the
          Services are the property of their respective owners and may not be
          copied, imitated or used, in whole or in part, without the permission
          of the applicable trademark holder. Reference to any products,
          services, processes or other information by name, trademark,
          manufacturer, supplier or otherwise does not constitute or imply
          endorsement, sponsorship or recommendation by Fame.
        </p>
        <h3>9. DMCA Notice and Procedure for Copyright Infringement Claims</h3>
        <p>
          As Fame asks others to respect its intellectual property rights, it
          respects the intellectual property rights of others. Fame, in
          appropriate circumstances, may remove from the Services information
          that might infringe the intellectual property rights of others.{' '}
        </p>
        <p>
          <b>a) Procedure for Reporting Copyright Infringements</b>
        </p>
        <p>
          If you believe your work has been used or copied in a way that
          constitutes copyright infringement and such infringement is occurring
          on the Fame Services, you may submit a notification pursuant to the
          Digital Millennium Copyright Act (“DMCA”) by providing our Designated
          Agent with the following information in writing (see 17 U.S.C
          512(c)(3) for further detail):
        </p>
        <ul>
          <p>
            An electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright’s interest;
          </p>
          <p>
            A description of the copyrighted work that you claim has been
            infringed, including the URL (i.e., web page address) of the
            location where the copyrighted work exists or a copy of the
            copyrighted work;
          </p>
          <p>
            Identification of the URL or other specific location on the Fame
            Services where the material that you claim is infringing is located;
            your address, telephone number, and E-mail address;
          </p>
          <p>
            A statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law; and
          </p>
          <p>
            A statement by you, made under penalty of perjury, that the above
            information in your notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner's
            behalf.
          </p>
        </ul>
        <p>
          You acknowledge that if you fail to comply with all of the
          requirements of this section, your DMCA notice may not be valid and we
          will have no obligation to respond or acknowledge receipt of your
          notice. Please note that you will be liable for damages (including
          costs and attorneys’ fees) if you misrepresent or make bad faith
          claims that any material on Fame Services infringes your copyrights.
          You can contact our Designated Agent via e-mail at hi@fame.xyz
        </p>
        <p>
          THE DESIGNATED AGENT SHOULD BE CONTACTED ONLY IF YOU BELIEVE THAT YOUR
          WORK HAS BEEN USED OR COPIED IN A WAY THAT CONSTITUTES COPYRIGHT
          INFRINGEMENT AND SUCH INFRINGEMENT IS OCCURRING ON THE FAME SERVICES.
          ALL OTHER INQUIRIES DIRECTED TO THE DESIGNATED AGENT WILL NOT RECEIVE
          A RESPONSE.
        </p>
        <p>
          <b>b) Receipt of Proper Infringement Notification</b>
        </p>
        <p>
          Once a proper bona fide infringement notification has been received by
          the Designated Agent, it is Fame’s policy:
        </p>
        <ul>
          <p>To remove or disable access to the infringing content;</p>
          <p>
            To notify the content provider, member, or user (“
            <b>Content Provider</b>”) that it has removed or disabled access to
            the content; and
          </p>
          <p>
            That repeat offenders will have the infringing content removed from
            the system and that Fame may terminate such Content Provider’s
            access to the P Fame Services.
          </p>
        </ul>
        <p>
          <b>c) Procedure to Supply a Counter-Notice to the Designated Agent</b>
        </p>
        <p>
          If the Content Provider believes that the content that was removed or
          to which access was disabled is either not infringing or the Content
          Provider believes that it has the right to post and use such content,
          the Content Provider may send the Designated Agent a counter-notice,
          which must contain the following:
        </p>
        <ul>
          <p>
            Content Provider’s contact information, including full legal name
            (not that of a company) of the submitter, an email address, a
            physical address, and a phone number.{' '}
          </p>
          <p>
            The location, including any URL, of the content that has been
            removed or disabled.
          </p>
          <p>
            The following statement, to which Content Provider must agree: "I
            consent to the jurisdiction of the Federal District Court for the
            district in which my address is located, or if my address is outside
            of the United States, the judicial district in which Fame is
            located, and will accept service of process from the claimant."
          </p>
          <p>
            The following statement: "I swear, under penalty of perjury, that I
            have a good faith belief that the material was removed or disabled
            as a result of a mistake or misidentification of the material to be
            removed or disabled."
          </p>
          <p>
            A physical or electronic signature. To satisfy this requirement,
            Content Provider may type the submitter’s full legal name (not that
            of a company) at the bottom of your electronic counter-notification.
          </p>
        </ul>
        <p>
          If a counter-notice is received by the Designated Agent, Fame may send
          a copy of the counter-notice to the original complaining party
          informing that person that it may replace the removed content or cease
          disabling it in ten business days.
        </p>
        <p>
          If Fame does not receive notice within ten business days that the
          original complaining party is seeking a court order to prevent further
          infringement of the content, we may replace or cease disabling access
          to the material that was removed. The decision to replace or cease
          disabling access to any content is at Fame’s sole discretion.{' '}
        </p>
        <h3>10. Indemnification</h3>
        <p>
          To the fullest extent permitted by applicable law, you agree to
          indemnify, defend and hold harmless Fame, and our respective past,
          present and future employees, officers, directors, contractors,
          consultants, equity holders, suppliers, Creators, vendors, service
          providers, parent companies, subsidiaries, affiliates, agents,
          representatives, predecessors, successors and assigns (individually
          and collectively, the “Fame Parties”), from and against all actual or
          alleged claims, damages, awards, judgments, losses, liabilities,
          obligations, penalties, interest, fees, expenses (including, without
          limitation, attorneys’ fees and expenses) and costs (including,
          without limitation, court costs, costs of settlement and costs of
          pursuing indemnification and insurance), of every kind and nature
          whatsoever, whether known or unknown, foreseen or unforeseen, matured
          or unmatured, suspected or unsuspected, in law or equity, whether in
          tort, contract or otherwise (collectively, “Claims”), including, but
          not limited to, damages to property or personal injury, that are
          caused by, arise out of or are related to (a) your use or misuse of
          the Services, (b) any feedback you provide, (c) your violation of
          these Terms, and/or (d) your violation of any right(s) of any third
          party. You agree to promptly notify Fame of any Claim(s) and shall
          cooperate fully with the Fame Parties in defending such Claims. You
          further agree that the Fame Parties shall have control of the defense
          or settlement of any third party Claims. THIS INDEMNITY IS IN ADDITION
          TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN ANY WRITTEN
          AGREEMENT(S) BETWEEN YOU AND FAME.
        </p>
        <h3>11. Disclaimers</h3>
        <p>
          EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY FAME, THE
          SERVICES, CONTENT CONTAINED THEREIN, AND ANY DIGITAL ASSET(S) LISTED
          THEREIN ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
          WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. FAME
          (AND ITS AFFILIATE(S), LICENSOR(S) AND/OR OTHER BUSINESS ASSOCIATE(S))
          MAKE NO WARRANTY THAT THE SERVICES: (A) WILL MEET YOUR REQUIREMENTS;
          (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE OR
          ERROR-FREE BASIS; OR (C) WILL BE ACCURATE, RELIABLE, COMPLETE, LEGAL
          OR SAFE. FAME DISCLAIMS ALL OTHER WARRANTIES OR CONDITIONS, EXPRESS OR
          IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OR
          CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE
          AND NON-INFRINGEMENT AS TO THE SERVICES OR CONTENT CONTAINED THEREIN.
          FAME DOES NOT REPRESENT OR WARRANT THAT CONTENT ON THE SERVICES IS
          ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WE WILL NOT BE
          LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN IN RELIANCE ON
          MATERIAL OR INFORMATION CONTAINED ON THE SERVICES. WHILE FAME ATTEMPTS
          TO MAKE YOUR ACCESS TO AND USE OF THE SERVICES AND CONTENT SAFE, FAME
          CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SERVICES, CONTENT OR
          OUR SERVERS IS/ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE
          CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE.
          YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND
          DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR
          ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
        </p>
        <p>
          WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
          RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF ANY
          DIGITAL ASSETS OR NFT CONTENT, INCLUDING BUT NOT LIMITED TO ANY
          LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR, SUCH AS
          FORGOTTEN PASSWORDS, PRIVATE KEYS OR SEED PHRASES, INCORRECTLY
          CONSTRUCTED TRANSACTIONS OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR
          DATA LOSS; (C) CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS TO
          APPLICATIONS; OR (E) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES,
          INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING,
          BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SERVICES OR ANY
          DIGITAL ASSET(S).
        </p>
        <p>
          Nothing in these Terms shall exclude or limit liability of either
          party for fraud, death or bodily injury caused by gross negligence,
          violation of laws, or any other activity that cannot be limited or
          excluded by legitimate means.
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN
          CONTRACTS WITH CONSUMERS, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU,
          SOLELY TO THE EXTENT SUCH EXCLUSION IS SO PROHIBITED BY APPLICABLE
          LAW.
        </p>
        <h3>12. Limitation of Liability</h3>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL FAME OR ITS
          AFFILIATES, OR ITS OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          CONTRACTORS, AGENTS, OFFICERS OR DIRECTORS, BE LIABLE FOR ANY
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
          INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
          EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
          OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
          LOSS OF DIGITAL ASSETS, LOSS OF ACCESS TO ANY DIGITAL WALLET, OR ANY
          OTHER DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR
          IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICES,
          INCLUDING BUT NOT LIMITED TO ANY CONTENT ON THE SERVICES OR ANY OTHER
          WEBSITES AND MOBILE APPLICATIONS OR ANY ITEMS OBTAINED THROUGH THE
          SERVICES OR SUCH OTHER WEBSITES AND MOBILE APPLICATIONS, WHETHER
          CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR
          OTHERWISE, EVEN IF FORESEEABLE.
        </p>
        <p>
          IN NO EVENT WILL FAME’S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO
          THE SERVICES, THE ACCESS TO AND USE OF THE SERVICE, CONTENT, DIGITAL
          ASSETS OR ANY PRODUCTS OR SERVICES PURCHASED ON THE SERVICES EXCEED
          THE GREATER OF (A) $100 OR (B) THE AMOUNT RECEIVED BY FAME FROM YOU
          RELATED TO THE DIGITAL ASSETS THAT ARE THE SUBJECT OF THE APPLICABLE
          CLAIM.
        </p>
        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR
          AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR
          CLAIM SHALL BE DEEMED PERMANENTLY WAIVED AND BARRED.
        </p>
        <p>
          THIS SECTION DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
          LIMITED UNDER APPLICABLE LAW. BECAUSE SOME STATES OR JURISDICTIONS DO
          NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR
          CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS
          THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH
          STATES OR JURISDICTIONS, OUR LIABILITY IS LIMITED TO THE EXTENT
          PERMITTED BY LAW, THEREBY MINIMIZING OUR LIABILITY TO YOU TO THE
          LOWEST AMOUNT PERMITTED BY APPLICABLE LAW.
        </p>
        <h3>13. Dispute Resolution</h3>
        <p>
          <b>
            This Section includes an arbitration agreement and an agreement that
            all claims will be brought only in an individual capacity (and not
            as a class action or other representative proceeding). Please read
            it carefully.
          </b>
        </p>
        <p>
          <b>Informal Process.</b> You agree that in the event of any dispute
          between you and Fame, you will first contact Fame and make a good
          faith sustained effort to resolve the dispute before resorting to more
          formal means of resolution, including without limitation any court
          action.
        </p>
        <p>
          <b>Agreement to Arbitrate.</b> After the informal dispute resolution
          process, any remaining dispute, controversy or claim (collectively,
          “Dispute”) relating in any way to these Terms or Fame’s services
          and/or products, including but not limited to the Services, or
          relating in any way to the communications between you and Fame or any
          other user of the Services, will be finally resolved by binding
          arbitration. This mandatory arbitration agreement applies to you and
          to Fame. However, this arbitration agreement does not (a) govern any
          Dispute by Fame for infringement of its intellectual property or
          access to the Services that is unauthorized or exceeds authorization
          granted in these Terms, or (b) bar you from making use of applicable
          small claims court procedures in appropriate cases.
        </p>
        <p>
          <b>
            You agree that the U.S. Federal Arbitration Act governs the
            interpretation and enforcement of this provision, and that you and
            Fame are each waiving the right to a trial by jury or to participate
            in a class action.
          </b>{' '}
          This arbitration provision will survive any termination of these
          Terms.
        </p>
        <p>
          If you wish to begin an arbitration proceeding, after following the
          informal dispute resolution procedure, you must send a letter
          requesting arbitration and describing your claim to: hi@fame.xyz
        </p>

        <p>
          <b>Arbitration Procedure and Rules.</b> The arbitration will be
          administered by the American Arbitration Association (“AAA”), under
          its rules including the Commercial Arbitration Rules (“Commercial
          Rules”) by a sole arbitrator. You can access the AAA Commercial Rules
          at https://www.adr.org/Rules or by calling +1 800-778-7879. Payment of
          all filing, administration and arbitrator fees will be governed by the
          AAA's rules. You and Fame shall endeavor to agree upon the arbitrator,
          and if you and Fame fail to do so within twenty-one (21) days of the
          commencement of the Arbitration, the appointment shall be made by AAA
          in accordance with the Commercial Arbitration Rules. Fame will
          reimburse those fees for claims where the amount in dispute is less
          than $10,000, unless the arbitrator determines the claims are
          frivolous, and we will not seek attorneys’ fees and costs in
          arbitration unless the arbitrator determines the claims are frivolous.
        </p>
        <p>
          The arbitrator, and not any federal, state or local court, will have
          exclusive authority to resolve any dispute relating to the
          interpretation, applicability, unconscionability, arbitrability,
          enforceability or formation of this arbitration agreement, including
          any claim that all or any part of this arbitration agreement is void
          or voidable. However, the preceding sentence will not apply to the
          “Class Action Waiver” section below.
        </p>
        <p>
          <b>
            Class Action Waiver.{' '}
            <span>
              Any Claim must be brought in the respective party’s individual
              capacity, and not as a plaintiff or class member in any purported
              class, collective, representative, multiple plaintiff or similar
              proceeding
            </span>
          </b>{' '}
          (“Class Action”). The parties expressly waive any ability to maintain
          any Class Action in any forum. If the Claim is subject to arbitration,
          the arbitrator will not have authority to combine or aggregate similar
          claims or conduct any Class Action nor make an award to any person or
          entity not a party to the arbitration. Any claim that all or part of
          this Class Action Waiver is unenforceable, unconscionable, void or
          voidable may be determined only by a court of competent jurisdiction
          and not by an arbitrator. The parties understand that any right to
          litigate in court, to have a judge or jury decide their case or to be
          a party to a class or representative action is waived, and that any
          claims must be decided individually, through arbitration.
        </p>
        <p>
          <b>Waiver of Jury Trial.</b> If for any reason a claim proceeds in
          court rather than in arbitration, you and Fame each waive any right to
          a jury trial.
        </p>

        <h3>14. Miscellaneous</h3>
        <p>
          <b>Governing Law and Venue.</b> These Terms, your access to and use of
          the Services and Content, and your participation in the Services,
          shall be governed by and construed and enforced in accordance with the
          laws of the State of Delaware, without regard to conflict of law rules
          or principles of the State of Delaware, or any other jurisdiction that
          would cause the application of the laws of any other jurisdiction. Any
          dispute between the parties that is not subject to arbitration or
          cannot be heard in small claims court, shall be resolved in the state
          or federal courts sitting in the State of Delaware.
        </p>

        <p>
          <b>Severability.</b> If any term, clause or provision of these Terms
          is held invalid or unenforceable, then that term, clause or provision
          will be severable from these Terms and will not affect the validity or
          enforceability of any remaining part of that term, clause or
          provision, or any other term, clause or provision of these Terms.
        </p>

        <p>
          <b>Waver.</b> If we fail to insist that you perform any of your
          obligations under these Terms, or if we do not enforce our rights
          against you, or if we delay in doing so, that will not mean that we
          have waived our rights against you and will not mean that you do not
          have to comply with those obligations. If we do waive a default by
          you, we will only do so in writing, and that will not mean that we
          will automatically waive any later default by you.
        </p>

        <p>
          <b>Assignability.</b> These Terms are personal to you, and are not
          assignable, transferable or sublicensable by you except with Fame’s
          express written consent.
        </p>

        <p>
          <b>Notices.</b> All notices under these Terms will be in writing and
          will be deemed to have been duly given when received, if personally
          delivered or sent by certified or registered mail, return receipt
          requested; when receipt is electronically confirmed, if transmitted by
          facsimile or e-mail; or the day after it is sent, if sent for next day
          delivery by recognized overnight delivery service.
        </p>
      </div>
    </div>
  );
};

import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import styles from './UnSubscribe.module.scss';

export const UnSubscribe: React.FC = () => {
  const router = useLocation();
  const path = router.search.split('&signature=');
  const signature = path[1];
  const unsubCheckBox = useRef<HTMLInputElement | null>(null);
  const [email, setEmail] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);

  const getEmail = async () => {
    try {
      if (step == 0) {
        const res = await (
          await fetch(
            `https://waitlist.lovespace.app/api/whitelist/unsubscribe?email=${email}`
          )
        ).text();
        console.log(res);
      } else {
        const res = await (
          await fetch(
            `https://waitlist.lovespace.app/api/whitelist/unsubscribe?email=${email}&signature=${signature}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
        ).json();
        console.log(res);
        setStep(2);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    setEmail(path[0].split('?email=')[1]);
    if (signature != null) {
      setStep(1);
    }
  }, []);
  return (
    <div className={styles.unsubscribePage}>
      <div className={styles.unsubscribeContent}>
        {step === 2 ? (
          <>
            <h1>
              You've successfully been unsubscribed from Lovespace e-mail list.
            </h1>
            <p>Didn't mean to unsubscribe?</p>
            <button onClick={getEmail}>Subscribe</button>
          </>
        ) : (
          <>
            <h1>Sorry to see you go</h1>
            {step === 0 ? (
              <>
                <p>
                  Please enter your email address below to unsubscribe from
                  Lovespace e-mail list.
                </p>
                <div className={styles.email}>
                  <h4>E-MAIL</h4>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </>
            ) : (
              <p>
                If you wish to no longer receive emails, please click the
                "Unsubscribe" button below.
              </p>
            )}

            <div className={styles.checkbox}>
              <input
                ref={unsubCheckBox}
                type="checkbox"
                id="unsubscribe"
                name="unsubscribe"
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
              />
              <label htmlFor="unsubscribe">
                I wish to no longer receive emails from Lovespace.
              </label>
            </div>
            <button
              disabled={!unsubCheckBox.current?.checked}
              onClick={getEmail}
            >
              Unsubscribe
            </button>
          </>
        )}
      </div>
    </div>
  );
};

import styles from '../privacy/Privacy.module.scss';

export const About: React.FC = () => {
  return (
    <div className={styles.textPage}>
      <div className={styles.textContent}>
        <h1>Date and Earn.</h1>
        <h3>The Hunt is Over.</h3>
        <p>
          <i>Amore,</i>
          <br />
          <br />
          Allow me to introduce myself. My name is Amoretto. And please, don’t
          continue reading this letter if you are not ready to change your life.
          <i> Subito.</i>
          <br />
          <br />
          You kept reading, didn’t you? I guess I have no other choice but to
          introduce you to a new way of dating.
        </p>
        <h3>Dating today sucks.</h3>
        <p>
          We all want to date - you know - meet “the one”. But how can we
          maximize our chances to do this? There are 7 billion people in the
          world, but somehow everyone I meet is…not for me. For the ones I like,
          somehow they are unreachable! <i>Frustrante!</i>
          <br />
          <br />
          Dating apps can certainly help… but most dating apps today have too
          many unverified ‘weirdos’, and charge you large monthly fees as they
          feed off your superlikes and hopes and dreams of finding your other
          half. They care about maximizing user count by allowing anyone to join
          their app. They don’t care about you, they care about themselves.
          <br />
          <br />
          Have we been played? <i>Lordo.</i>
        </p>
        <h3>Meet Fame.</h3>
        <p>
          <i>Amore,</i> meet Fame, the first NFT-based dating app on blockchain.
          <br />
          <br />
          No more unverified weirdos. We only allow verified owners of Top 10
          NFT projects and other carefully background-checked, highly qualified
          individuals, to join. Bored Apes, CryptoPunks, Azukis -{' '}
          <i>Benvenuta!</i>
          <br />
          <br />
          No more monthly fees. Actually, instead of paying money, you earn.
          Everytime you match and chat with your match, you earn our token as a
          reward! <i>Buona.</i>
          <br />
          <br />
          No more scammers. Fame community can vote people in and out. Met
          someone that looks nothing like their online profile? Got someone
          sending you weird pics? Vote them out. We are a decentralized
          community. We decide who belongs here.
        </p>
        <h3>Now, go find your other half.</h3>
        <p>
          It’s time we change the dating scene.
          <br />
          <br />
          Each of you initial members will receive 5 special invite codes to
          build a clean dating community with reliable, verified members.
          <br />
          <br />
          WE decide who we date. WE decide who we meet. WE decide who belongs in
          this community. We will all find our other half, and we will all help
          each other to get there, together.
          <br />
          <br />
          Grazie amore, and welcome. I will see you on the other side.
          <br />
          <br />
          <br />
          Ciao, <br />
          Fame.
        </p>
        {/* <img src="/img/sign.png" alt="sign" /> */}
      </div>
    </div>
  );
};

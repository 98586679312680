import { Link, useLocation } from 'react-router-dom';
import styles from '../header/Header.module.scss';

export const Footer: React.FC = () => {
  const router = useLocation();
  const toCurrentRouteClass = (expectedRoute: string) =>
    router.pathname === expectedRoute ? styles.currentRoute : '';

  return (
    <div className={styles.footer}>
      <p>&copy; 2022 Fame</p>
      <Link className={`${toCurrentRouteClass('/privacy')}`} to="/privacy">
        PRIVACY POLICY
      </Link>
      <Link className={`${toCurrentRouteClass('/terms')}`} to="/terms">
        TERMS AND CONDITIONS
      </Link>
    </div>
  );
};

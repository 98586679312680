import { useState } from 'react';
import { useParams } from 'react-router';
import styles from './Home.module.scss';

const ENDPOINT_URL = 'https://waitlist.lovespace.app/api/whitelist/join';

export const Home: React.FC = () => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [about, setAbout] = useState<string>('');
  const res: any = useParams();
  const referrer = res.referrer ?? '';
  const [err, setErr] = useState<string>('');
  const [rank, setRank] = useState<string>('282,191');
  const [link, setLink] = useState<string>('https://lovespace.app/189EK');
  const [copied, setCopied] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const submitEmail = async () => {
    if (about === '' || email === '') {
      setErr('Please fill all fields');
      return;
    }
    if (!validateEmail(email)) {
      setErr(email + ' is not valid email');
      return;
    }
    setErr('');
    const body = {
      email: email,
      about: about,
      referrer: referrer,
    };
    const res = await (
      await fetch(ENDPOINT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
    ).json();
    if (res && res.rank && res.ref_code) {
      setRank(res.rank);
      setLink('https://lovespace.app/' + res.ref_code);

      setSubmitted(true);
    } else if (res.detail) {
      setErr(res.detail);
    } else {
      setErr('Something is wrong. Please try again later.');
      console.log(res);
    }
  };

  return (
    <div className={styles.homeContainer}>
      <div className={styles.content}>
        {!submitted ? (
          <>
            <h1>Hi! We are currently invite only…</h1>
            <h2>
              World's first NFT dating app.
              <br />
              Match-to-Earn.
            </h2>
          </>
        ) : (
          <h1>Your e-mail has been submitted!</h1>
        )}
        <p>
          {!submitted
            ? 'Sign up on our waitlist or receive invite from existing user! We review each candidate manually.'
            : 'Share your referral code to climb leaderboard. Every time someone joins the waitlist using your referral link, you’ll move up to the leaderboard.'}
        </p>
        {submitted ? (
          <div className={styles.inputGroup}>
            <div className={styles.rankBox}>
              Your rank: <b>{rank}</b>
            </div>
            <div className={styles.copyBox}>
              <p>{link}</p>
              {copied && <span>COPIED!</span>}
              <img
                src="/img/copy.svg"
                alt="copy clipboard"
                onClick={() => {
                  navigator.clipboard.writeText(link);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 5000);
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <div className={styles.inputGroup}>
              <div className={styles.emailBox}>
                <span>E-MAIL</span>
                <input
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => {
                    setErr('');
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className={styles.aboutBox}>
                <span>TELL US ABOUT YOURSELF</span>
                <input
                  type="text"
                  placeholder="i.e. Ref Code, Social Media"
                  value={about}
                  onChange={(e) => {
                    setErr('');
                    setAbout(e.target.value);
                  }}
                />
              </div>
              <div className={styles.btnJoin} onClick={submitEmail}>
                Apply
              </div>
            </div>
            {err && <p className={styles.errBox}>{err}</p>}
          </>
        )}
        <h5>AVAILABLE ON APP STORES</h5>
        <div className={styles.stores}>
          <a
            href="https://play.google.com/store/apps/details?id=com.fame.fameapp"
            target="_blank"
          >
            <img src="/img/googleplay.svg" alt="google play" />
          </a>
          <a
            href="https://apps.apple.com/us/app/fame-web3-dating-app/id1623874664"
            target="_blank"
            // onClick={(e) => {
            //   e.preventDefault();
            // }}
          >
            <img src="/img/appstore.svg" alt="app store" />
          </a>
        </div>
      </div>
      <div className={styles.phone}>
        <img src="/img/phone.png" alt="phone" />
      </div>
    </div>
  );
};

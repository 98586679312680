import styles from './Privacy.module.scss';

export const Privacy: React.FC = () => {
  return (
    <div className={styles.textPage}>
      <div className={styles.textContent}>
        <h1>FAME PRIVACY POLICY</h1>
        <h3>Last Updated: 3 May 2022</h3>
        <p>
          This Privacy Policy ("Policy") describes how Spacerunners, d/b/a Fame
          and its affiliates (Fame, “Spacerunners”, “we”, “us”, “our”) collects,
          uses, and discloses information related to the users of the website
          https://lovespace.app/ (“Website”) and/or our mobile application Fame
          (“App”) and any other related and connected platforms and other online
          and offline interactions (collectively, the “Services”).
          <br />
          <br />
          Your use of our Services is subject to our Terms of Use which includes
          applicable terms governing limitations on liability and the resolution
          of disputes. By using the Services, you accept and agree to the
          practices described in this Policy. If you do not agree with this
          Policy, please do not use the Services or provide us with any Personal
          Information (as defined below).
        </p>
        <h3>Personal Information</h3>
        <p>
          For purposes of this Policy, unless otherwise required by applicable
          law, “Personal Information” means any information that (directly or
          indirectly) identifies, relates to, describes, or is reasonably
          capable of being associated, linked or linkable with a particular
          individual or household, including any information that is subject to
          applicable data protection laws.
        </p>
        <h3>Scope</h3>
        <p>
          This Policy applies to our collection, use and disclosure of Personal
          Information related to the users of our Services, and applies to all
          users of our Services.
        </p>
        <h3>Collection of Personal Information</h3>
        <p>
          The Personal Information we collect varies depending upon the nature
          of the Services provided or used and our interactions with
          individuals.
          <br />
          <br />
          <strong>
            <i>Categories of Personal Information. </i>
          </strong>{' '}
          While the Personal Information we collect varies depending upon the
          circumstances, we may collect the following categories of Personal
          Information (subject to applicable legal requirements and
          restrictions):
        </p>
        <ul>
          <li>
            <p>
              <i>Name, contact information and other identifiers</i>:
              identifiers such as a name, username, account name, blockchain
              address, address, phone number, birth date, email address, and
              online identifier.
            </p>
          </li>
          <li>
            <p>
              <i>Customer records</i>: electronic customer records containing
              Personal Information. We may also collect your payment information
              as well but note this is processed by our third-party payment
              processor.
            </p>
          </li>
          <li>
            <p>
              <i>Device information</i>: internet protocol (IP) address, web
              browser type, operating system version, phone carrier and
              manufacturer, application installations, device identifiers,
              mobile advertising identifiers, and push notification tokens.
            </p>
          </li>
          <li>
            <p>
              <i>Protected classifications</i>: characteristics of protected
              classifications under applicable law such as race, sex, age, and
              disability.
            </p>
          </li>
          <li>
            <p>
              <i>Communications</i>: direct communication, web forms, online
              polls, or interactions with our blogs and posts.
            </p>
          </li>
          <li>
            <p>
              <i>Commercial information</i>: including records of products or
              services purchased, obtained, or considered, or other purchasing
              or use histories or tendencies.
            </p>
          </li>
          <li>
            <p>
              <i>Usage data</i>: internet or other electronic network activity
              information including, but not limited to, browsing history,
              search history, and information regarding a consumer’s interaction
              with an Internet website, application, or advertisement.
            </p>
          </li>
          <li>
            <p>
              <i>Location data</i>: location information about a particular
              individual or device, general location information (for example,
              your IP address may indicate your more general geographic region).
              However, this information is not precise geolocation.{' '}
            </p>
          </li>
          <li>
            <p>
              <i>Profiles and inferences</i>: inferences drawn from any of the
              information identified above to create a profile reflecting a
              resident’s preferences and characteristics.
            </p>
          </li>
        </ul>
        <p>
          <strong>
            <i>Sources of Personal Information. </i>
          </strong>
          We may collect Personal Information about individuals:
        </p>
        <ul>
          <li>
            <p>
              <i>Directly from you</i>: such as when you create an online
              account with us (the “User Account”), participate in a
              transaction, communicate on the Services, participate in contests
              and promotions we offer, register to receive marketing and other
              communications from us, or contact us for customer support
              purposes.
            </p>
          </li>
          <li>
            <p>
              <i>From third parties</i>: such as affiliate and other third
              parties, public records, third-party providers of services to us
              (e.g., fraud detection, identity verification and security),
              consumer data resellers, social networks, joint marketing partners
              and affiliate companies.
            </p>
          </li>
          <li>
            <p>
              <i>Related to your use of our Services</i>: including information
              we collect automatically when you use our website or interact with
              us, or information we derive about you and your preferences or
              interests based on the Personal Information we collect and our
              interactions with you.
            </p>
          </li>
        </ul>
        <p>
          <strong>
            <i>Information We Collect from You</i>
          </strong>
          . We may collect Personal Information from you related to:
        </p>
        <ul>
          <li>
            <p>
              <i>Registration, accounts, and profiles</i>: when you register for
              a User Account with us, we collect your blockchain address, first
              and last name, username, date of birth, gender, location, and
              email address.
            </p>
          </li>
          <li>
            <p>
              <i>Marketing, surveys and events</i>: if you sign up to receive
              news, updates, offers and other marketing communications from us,
              or attend any events hosted by us, we may collect your name,
              contact information, and information about your preferences.{' '}
            </p>
          </li>
          <li>
            <p>
              <i>Your communications and requests</i>: when you email us, call
              us, or otherwise send us communications, we collect and maintain a
              record of your contact details, communications and our responses.
            </p>
          </li>
        </ul>
        <p>
          <strong>Information We Collect from Third Parties</strong>. We may
          collect Personal Information about you from third-party sources (which
          may be combined with other Personal Information we have collected
          about you), such as:
        </p>
        <ul>
          <li>
            <p>
              <i>Social media</i>: if you choose to link, create, or log in to
              your User Account with a social media service (e.g., Twitter,
              Facebook, Instagram, etc.), we collect your permission to access
              certain information from your profile with that social media
              service. If you post or if you engage with a separate social media
              service or interact with us on our page with any social media
              service, we may collect Personal Information about you related to
              those interactions.
            </p>
          </li>
          <li>
            <p>
              <i>Other</i>: we may obtain Personal Information, such as
              demographic information or updated contact details, from third
              parties; we may also collect information from public records.
            </p>
          </li>
        </ul>
        <p>
          <strong>
            <i>Information We Collect or Derive About You Automatically</i>
          </strong>
          . We may collect or derive Personal Information about your use of our
          Services, or other interactions with us.
        </p>
        <ul>
          <li>
            <p>
              <i>Website and App</i>: We and our third-party providers may use
              cookies, pixels, tags, log-files, and other technologies to
              collect information about a user from their browser or device,
              including, without limitation, your browser type, device type,
              operating system, software version, phone model, phone operating
              system, platform-dependent information, requested document,
              referring URL, date and time of your visit, clickstream data
              (e.g., about the pages you view, links you click and date and time
              stamps for your activities on our Website), and the IP address
              associated with your transactions in the system. The information
              that we collect automatically may be linked with other Personal
              Information we have collected.{' '}
            </p>
          </li>
        </ul>

        <h3>Use of Personal Information</h3>
        <p>
          We will only process your Personal Information where we have legal
          grounds to do so. We may use Personal Information for a variety of
          purposes, including, without limitation:{' '}
        </p>
        <ul>
          <li>
            <p>
              <i> Providing support and services</i>: including to provide our
              Services, operate our Website, App and online services, and
              interact with you on our Services; to respond to your inquiries;
              to provide troubleshooting, fulfill your orders and requests,
              process your payments and provide technical support; and for other
              customer service and support purposes. Our lawful basis is to
              fulfil any contractual terms with you.
            </p>
          </li>
          <li>
            <p>
              <i>Analyzing and improving our business</i>: including to better
              understand how users access and use our Services, to evaluate and
              improve our Services and business operations, and to develop new
              features, offerings, and services; to conduct surveys and other
              evaluations (such as customer satisfaction surveys); to monitor
              consumer interest in our products and Services; to troubleshoot
              problems that may arise on the Services; to improve the accuracy
              of our customer database; to increase our understanding of our
              customer database; to increase our understanding of our customers;
              and for other research and analytical purposes. Our lawful basis
              is our legitimate business interests in understanding and
              improving our Services.
            </p>
          </li>
          <li>
            <p>
              <i>Personalizing content and experiences</i>: including to tailor
              content we send or display on our Website and other Services and
              to otherwise personalize your experiences. To assist in these
              purposes, as well as the other purposes identified in this Policy,
              at certain times, we create a profile relating to you in order to
              show you the content that we think you might be interested in and
              to display the content according to your preferences. Our lawful
              basis is our legitimate business interests in offering a more
              personalized service.
            </p>
          </li>
          <li>
            <p>
              <i>Advertising, marketing and promotional purposes</i>: including
              to reach you with more relevant ads and to evaluate, measure and
              improve the effectiveness of our ad campaigns; to send you
              newsletters, offers or other information we think may interest
              you; to contact you about our Services, products, or other
              information we think may interest you; and to identify potential
              new customers. Our lawful basis is your consent to choose to
              subscribe to any newsletter or marketing (and you can unsubscribe
              at any time).
            </p>
          </li>
          <li>
            <p>
              <i>Securing and protecting our business</i>: including to protect
              and secure our business operations, assets, Services, network and
              information and technology resources; to investigate, prevent,
              detect and take action regarding fraud, unauthorized access,
              situations involving potential threats to the rights or safety of
              any person or third-party, or other unauthorized activities or
              misconduct. Our lawful basis is our legitimate business interests
              in protecting our business and services.
            </p>
          </li>
          <li>
            <p>
              <i>Defending our legal rights</i>: including to manage and respond
              to actual and potential legal disputes and claims, and to
              otherwise establish, defend or protect our rights or interests,
              including in the context of anticipated or actual litigation with
              third parties. Our lawful basis is our legitimate business
              interests in protecting our business or our need to defend
              ourselves legally.
            </p>
          </li>
          <li>
            <p>
              <i>
                Auditing, reporting, corporate governance, and internal
                operations
              </i>
              : including relating to financial, tax and accounting audits;
              audits and assessments of our operations, privacy, security and
              financial controls, risk, and compliance with legal obligations;
              our general business, accounting, record keeping and legal
              functions; and related to any actual or contemplated merger,
              acquisition, asset sale or transfer, financing, bankruptcy or
              restructuring of all or part of our business. Our lawful basis is
              our legal obligations under relevant legislation such as tax
              reporting and our legitimate interests in running our governance
              programs.
            </p>
          </li>
          <li>
            <p>
              <i>Complying with legal obligations</i>: including to comply with
              the law, our legal obligations and legal process, such as
              warrants, subpoenas, court orders, and regulatory or law
              enforcement requests. Our lawful basis is compliance with
              applicable law.
            </p>
          </li>
          <li>
            <p>
              <i>For our legitimate business interests</i>: including our
              business interest in conducting and managing our business and
              enabling us to give you the best service/product and the best and
              most secure experience. We make sure we consider and balance any
              potential impact on you (both positive and negative) and your
              rights before we process your Personal Information for our
              legitimate interests. We do not use your Personal Information for
              activities where our interests are overridden by the impact on you
              (unless we have your consent or are otherwise required or
              permitted to by law). You can obtain further information about how
              we assess our legitimate interests against any potential impact on
              you in respect of specific activities by contacting us. Our lawful
              basis is this legitimate business interest.
            </p>
          </li>
        </ul>
        <p>
          <strong>
            <i>Aggregate and De-identified Data</i>
          </strong>
          . We may de-identify information and create anonymous and aggregated
          data sets and reports to assess, improve and develop our business,
          products and Services, prepare benchmarking reports on our industry
          and for other research, marketing and analytics purposes.
          De-identified information is not Personal Information and we may use
          de-identified information as allowed under applicable data protection
          laws.{' '}
        </p>
        <p>
          <strong>
            <i>We may combine information from different sources</i>
          </strong>
          . For example, we may combine information that we have collected
          offline with information we collect online, or we may combine
          information we get from a third-party with information we already
          have.
          <br />
          <br />
          If you submit any Personal Information relating to other people to us,
          you represent that you have the authority to do so and have informed
          that other person about the contents of this Policy.
        </p>
        <h3>Disclosure of Personal Information</h3>
        <p>
          We may share or disclose the Personal Information we collect as
          follows:
        </p>
        <ul>
          <li>
            <p>
              <i>Service providers</i>: We may disclose Personal Information
              with third-party service providers who use this information to
              perform services for us, such as hosting providers, auditors,
              advisors, consultants, customer service and/or support providers.
            </p>
          </li>
          <li>
            <p>
              <i>Advertising and Marketing Partners</i>: We may share Personal
              Information with third parties that provide advertising, campaign
              measurement, online and/or mobile analytics, and related services.
              These third parties may receive or access browsing and/or other
              data about your use of the Services, in order to help us better
              reach individuals with relevant ads and/or measure our ad
              campaigns, and/or to better understand how individuals interact
              with our Services overtime and across devices.{' '}
            </p>
          </li>
          <li>
            <p>
              <i>Subsidiaries, affiliates, and business partners</i>: We may
              share your Personal Information with our affiliated companies
              (i.e., our parent company and other companies under common
              ownership, control or management with us; and the Creators we have
              partnered with to offer exclusive content on our Services); they
              may use such Personal Information for the purposes set out in this
              Policy.
            </p>
          </li>
          <li>
            <p>
              <i>Legal compliance</i>: We may be required to share Personal
              Information in response to a valid court order, subpoena,
              government investigation, or as otherwise required by law. We also
              reserve the right to report to law enforcement agencies any
              activities that we, in good faith, believe to be unlawful. In
              addition, we may share certain Personal Information when we
              believe that doing so is reasonably necessary to protect the
              rights, property and safety of our company and/or others.
            </p>
          </li>
          <li>
            <p>
              <i>Business transfers</i>: We may disclose and/or transfer
              Personal Information as part of any actual or contemplated merger,
              sale, transfer of assets, acquisition, financing and/or
              restructuring of all or part of our business, bankruptcy or
              similar event, including related to due diligence conducted prior
              to such event where permitted by law.
            </p>
          </li>
          <li>
            <p>
              <i>Protect our rights</i>: We may disclose Personal Information
              where we believe it necessary to respond to claims asserted
              against us, to enforce or administer our agreements and terms, for
              fraud prevention, risk assessment, investigation and/or to protect
              the rights, property or safety of Fame or our affiliates,
              partners, clients, customers and/or others.
            </p>
          </li>
        </ul>
        <p>
          <strong>
            <i>Aggregated and De-identified Data</i>
          </strong>
          . We may share aggregate or de-identified information with third
          parties for research, marketing, advertising, analytics and/or other
          purposes.
        </p>
        <h3>
          What happens if you do not provide us with the Personal Information we
          request or ask that we stop processing your Personal Information?
        </h3>
        <p>
          If you do not provide the Personal Information we request, or if you
          withdraw your consent to the processing of your Personal Information,
          then you may be unable to use the Services.{' '}
        </p>
        <h3>Automated Decisions </h3>
        <p>
          Automated decisions are those made without human intervention that
          have a legal effect on you or other similarly significant effect. We
          do not carry out this type of processing activity.
        </p>
        <h3>Cookies and Analytics</h3>
        <p>
          We use cookies, pixels, tags, and other technologies, which may be
          provided by third parties, on our Services to enable certain
          functionality and for security and fraud detection and prevention, as
          well as to collect usage information about our Website and the emails
          that we send and to personalize content and provide more relevant ads
          and information. We may combine the information we collect via these
          technologies with other information, including Personal Information.
        </p>
        <p>
          <strong>
            <i>Cookies</i>
          </strong>
          Cookies are alphanumeric identifiers that are transferred to your
          computer through your web browser for record-keeping purposes. Some
          cookies enable you to log-in to our Services or save certain settings
          and preferences, while others allow us to track usage and activities
          on our Services, personalize content, or deliver more relevant ads.
          Most web browsers automatically accept cookies, but if you prefer, you
          can edit your browser options to block them in the future. The "Help"
          tab on the toolbar on most browsers will tell you how to prevent your
          computer from accepting new cookies, how to have the browser notify
          you when you receive a new cookie, or how to disable cookies
          altogether. If you block cookies, however, certain features on our
          Website may not be available or function properly.
          <br />
          <br />
          Third parties may use cookies and other similar technologies to
          collect or receive information from our Site and elsewhere on the
          Internet and use that information to provide you with targeted ads. If
          you would like to opt-out of such advertising practices on the
          particular device on which you are accessing this Privacy Policy,
          please go to http://optout.aboutads.info/. The Network Advertising
          Initiative also offers a means to opt-out of a number of advertising
          cookies. Please visit http://www.networkadvertising.org to learn more.
          Note that opting-out does not mean you will no longer receive online
          advertising. It does mean that the company or companies from which you
          opted-out will no longer deliver ads tailored to your preferences and
          usage patterns.
        </p>
        <p>
          <strong>
            <i>
              Pixel tags and embedded script (aka clear GIFs and web beacons)
            </i>
          </strong>
          . Pixel tags are tiny graphics with a unique identifier, similar in
          function to cookies. In contrast to cookies, which are stored on your
          computer’s hard drive, pixel tags are embedded invisibly on web pages.
          We may use these, in connection with our Website to, among other
          things, track the activities of the users of our Services, improve
          ads, personalize and manage content, and gather usage information
          about our Website. We may also use these in HTML emails to help us
          track email response rates, identify when our emails are viewed, and
          track whether our emails are forwarded.
        </p>
        <p>
          <strong>
            <i>Third-Party Analytics Tools</i>
          </strong>
          . Our Website uses automated devices and applications operated by
          third parties, such as Google Analytics, which uses cookies and
          similar technologies to collect and analyze information about use of
          the Website and report on activities and trends. This service may also
          collect information regarding the use of other websites, apps and
          online resources. You can learn about Google's practices by going to
          www.google.com/policies/privacy/partners/, and you can opt out of them
          by downloading the Google Analytics opt-out browser add-on, available
          at https://tools.google.com/dlpage/gaoptout.
        </p>
        <h3>Children's Privacy</h3>
        <p>
          Our Services are restricted for users of legal age and children under
          the age of 18 are not allowed to use our Services. Fame does not
          knowingly collect or maintain Personal Information from children we
          actually know at the time of collection are under the age of 13.
          Should we discover that we have collected Personal Information online
          from a child who is under 13, we will promptly delete that Personal
          Information. If you have concerns over the collection of children's
          Personal Information on the Services, please contact us at the
          information provided in the Contact Us section below. Although our
          Services are not directed to children under the age of 13, if your
          child uses our Services and you wish to review or delete your child’s
          Personal Information, you may so request by contacting us at the
          location or email address set forth below under ‘Contact Us.’{' '}
        </p>
        <h3>Security</h3>
        <p>
          The security of your Personal Information is important to us. We have
          put in place safeguards to protect the Personal Information we collect
          from unauthorized access, use and disclosure, and we take steps to
          ensure that all of our employees, agents, contractors and other third
          parties have similar adequate measures in place. We also have
          procedures to deal with any suspected Personal Information breach, and
          we will notify you and any applicable regulator when we are legally
          required to do so. However, we cannot guarantee that unauthorized
          access, hacking, data loss, or other breaches will never occur. We
          urge you to take steps to keep your Personal Information safe, such as
          choosing a strong password and logging out of your User Account and
          closing your web browser when finished using the Services.
        </p>
        <h3>Links to Third-party Websites</h3>
        <p>
          The Services provide links to various websites operated by third
          parties including, but not limited to, third-party sites that may
          display Fame trademarks. This Policy does not apply to third-party
          websites that are accessible through the Services, unless such website
          directs users or visitors to this Policy. When you click on one of
          these links, you will be transferred out of the Services and connected
          to the website of the organization or company that maintains that
          website. Even if an affiliation exists between us and a third-party
          website, we exercise no control over linked websites. Each of these
          linked websites maintains its own independent privacy and data
          collection policies and procedures. We encourage you to read the
          privacy policies of those other websites to learn how they collect,
          use, share, and secure your information before providing any Personal
          Information.
        </p>
        <h3>Retention</h3>
        <p>
          Except to the extent prohibited by law, and subject to this Policy, we
          will retain and use your Personal Information for as long as it is
          needed to provide you with any services, communications, information
          you have requested, or access to the Website, to document our business
          relationship with you, and as necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements. As soon as
          it is reasonable to assume your Personal Information is no longer
          needed or required, we will cease to retain your Personal Information,
          or remove the means by which the data can be associated with you.{' '}
        </p>
        <h3>Changes to this Policy</h3>
        <p>
          This Policy is kept under regular review and may be updated from time
          to time. When we make changes to this Policy, we will change the "Last
          Updated" date above. If a material change is made to this Policy, we
          may choose to provide notice to you in advance of such change, such as
          by posting notice of that change on the first page of this Policy or
          on our homepage, or by emailing your email address of record with us.{' '}
        </p>
        <h3>Changes to your Information </h3>
        <p>
          It is important that the information we hold about you is accurate and
          current. Please keep us informed as soon as possible if your Personal
          Information changes or is inaccurate during your relationship with us
          by using the contact details set out below.{' '}
        </p>
        <h3>California Shine the Light Law</h3>
        <p>
          Under California’s "Shine the Light" law (Cal. Civ. Code § 1798.83),
          California residents who provide us certain personal data are entitled
          to request and obtain from us, free of charge, information about the
          personal data (if any) we have shared with third parties during the
          immediately preceding calendar year for their own direct marketing
          use. Such requests may be made once per calendar year for information
          about any relevant third-party sharing in the prior calendar year.
          California residents who would like to make such a request may submit
          a request to the contact information provide below. The request should
          attest to the fact that the requester is a California resident, and
          provide a current California address. We are only required to respond
          to a customer request once during any calendar year. Please be aware
          that not all information sharing is covered by California’s "Shine the
          Light" law and only information sharing that is covered will be
          included in our response.{' '}
        </p>
        <h3>Supplemental Notice for Nevada Residents</h3>
        <p>
          If you are a resident of Nevada, you have the right to opt-out of the
          sale of certain Personal Information to third parties who intend to
          license or sell that Personal Information. You can exercise this right
          by contacting us at hi@lovespace.app with the subject line “Nevada Do
          Not Sell Request” and providing us with your name and the email
          address associated with your account. Please note that we do not
          currently sell your Personal Information as sales are defined in
          Nevada Revised Statutes Chapter 603A. If you have any questions,
          please contact us as set forth below.
        </p>

        <h3>Contact Us</h3>
        <p>
          If you have any concern about our privacy practices, please contact us
          by email at legal@Lovespace.app, or by writing to us at:
          hi@lovespace.app{' '}
        </p>
      </div>
    </div>
  );
};
